.container {
  display: flex;
  flex-direction: column;
  color:white;
  max-width: 20vw;
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 1.2vh;
  padding: 1vw;
}

.btn {
  background-color: black;
  border: 1px solid;
  border-color: #484848;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  width: 4vw;
  height: 4vh;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8vw;
}

.modalFilterStyle {
  background-color: #01F201;
}