.container {
  background-color: black;
  border: 1px solid;
  border-color: #01F201;
  border-radius: 1.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vh;
  padding: 2vw;
  z-index: 10;
}

.centerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tokenImg {
  width: 17.14vw;
}

.areYouSure {
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1.8vw;
  max-width: 30.26vw;
}

.warning {
  color: #818181;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  margin-top: 14px;
}

.confirmButton {
  background-color: #01F201;
  border: 1px solid;
  border-color: #01F201;
  border-radius: 15px;
  margin-top: 25px;
  width: 31.58vw;
  height: 8.4vh;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 3vw;
  cursor: pointer;
}

.cancelButton {
  background-color: transparent;
  color: #01F201;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 1.2vw;
  letter-spacing: 2px;
  margin-top: 15px;
  border-color: transparent;
  cursor: pointer;
}

/* Mint counter */
.counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.counterText {
  color: #7c0067;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.7vw;
}

.counterButton {
  background-color: black;
  border: 1px solid;
  border-color: #484848;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vw;
  font-weight: 800;
  width: 4.1vw;
  height: 4.3vh;
  font-family: 'Open Sans', sans-serif;
}

.minusButton {
  background-color: white;
  width: 0.8vw;
  height: 0.5vh;
}