@font-face {
  font-family: 'SuisseIntl-Medium';
  src: url(../../assets/fonts/SuisseIntl-Medium.otf) format('otf');
}

@font-face {
  font-family: 'SuisseIntl-Regular';
  src: url('../../assets/fonts/SuisseIntl-Regular.otf');
}

@font-face {
  font-family: 'SuisseIntl-Black';
  src: url('../../assets/fonts/SuisseIntl-Black.otf');
}