.container {
  padding: 10px;
  color: #01F201;
  background-color: #123708;
  border-radius: 0.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  font-family: 'SuisseIntl-Regular';
  width: 20vw;
  height: 4.2vh;
}