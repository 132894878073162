.container {
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4vh 5vw 4vh 5vw;
  background-image: url('../../assets/images/mutant_bg.png');
  background-size: 100vw 100vh;
}

.successMsg {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.topActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.middle {
  display: flex;
  justify-content: center;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.5vh;
}

.bottom * {
  font-family: 'Open Sans', sans-serif;
}

.row {
  position: relative;
  max-width: 60vw;
  display: flex;
  height: 10vh;
  width: 60vw;
  gap: 1.5vh;
}

.smallBtn {
  position: absolute;
  border: 1px solid;
  background-color: #005200;
  border-color: #01F201;
  border-radius: 15px;
  cursor: pointer;
  height: 100%;
  width: calc(50% - 0.75vh);
  color: #01F201;
  font-size: 1.6vw;
  font-weight: 800;
  flex: 1;
}

.smallBtnRight {
  left: calc(50% + 0.75vh);
}

.bigBtn {
  background-color: #01F201;
  border: 1px solid;
  border-color: #01F201;
  border-radius: 15px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.4vw;
  font-weight: 800;
  height: 100%;
  width: 100%;
  line-height: 3.6vh;
  flex: 1;
}

.smallText {
  font-size: 1.2vw;
  font-weight: 800;
}

.lowerMsg {
  font-size: 1.2vw;
  color: rgb(190, 190, 190);
}

.switchersContainer {
  position: absolute;
  display: flex;
  width: 40vw;
  margin-top: -18vh;
  color: white;
  justify-content: space-between;
}

.selector {
  aspect-ratio: 1 / 1;
  width: 1.2vw;
  cursor: pointer;
}