.container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 0.9vw;
}

.icon {
  width: 1.5vw;
}