/* The tank is essentially a modal that allows clicking on bottom layers... I guess that is called an overlay and not a modal. */
.container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  pointer-events: none;
}

.tank {
  width: auto;
  height: 65vh;
}

.testSubjectContainer {
  position: absolute;
  display: flex;
  margin-top: 10vh;
}

.testSubject {
  border-radius: 50%;
  height: 35vh;
}

.testSubjectMask {
  mask-image: radial-gradient(50.12% 50.12% at 50% 49.93%, #7bff5b 54.69%, rgba(255, 255, 255, 0) 100%);
  border-radius: 50%;
}

.switchersContainer {
  position: absolute;
  display: flex;
  width: min(28vw, 50vh);
  margin-top: 25vh;
  color: white;
  justify-content: space-between;
}

.selector {
  aspect-ratio: 1 / 1;
  width: 1.1vw;
  cursor: pointer;
  pointer-events: all;
}