/* The tank is essentially a modal that allows clicking on bottom layers... I guess that is called an overlay and not a modal. */
.container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  pointer-events: none;
}

.tank {
  width: auto;
  height: 85vh;
  max-height: 85vh;
}

.testSubjectContainer {
  position: absolute;
  display: flex;
  margin-top: 15vh;
}

.testSubject {
  border-radius: 50%;
  height: 40vh;
}

.testSubjectMask {
  mask-image: radial-gradient(50.12% 50.12% at 50% 49.93%, #FFFFFF 54.69%, rgba(255, 255, 255, 0) 100%);
  border-radius: 50%;
}