.container {
  top: 0;
  left: 0;
  display: flex;
  border: 1px solid;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: black;
  opacity: 0.9;
}

.filter {
  position: absolute;
  width: 65.29%;
  height: 44.11%;

  border-radius: 50%;
  filter: blur(15vw);
  opacity: 0.5;
}

.modalContent {
  position: absolute;
}

.removeImg {
  position: absolute;
  top: 5%;
  right: 5%;
  aspect-ratio: 1 / 1;
  width: 7%;
  z-index: 5;
  cursor: pointer;
}