.btn {
  background-color: black;
  border: 1px solid;
  border-color: rgb(46, 46, 46);
  border-radius: 5px;
  color: white !important;
  cursor: pointer;
  width: min(9.47vw, 20vh);
  height: min(8vw, 5.3vh);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
  font-size: min(0.8vw, 2vh);
}

.btn img {
  width: auto;
  margin-right: 8px;
  height: 60%;
}