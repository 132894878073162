.btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8.4vh;
  border-radius: min(0.7vw, 1vh);
  font-family: 'SuisseIntl-Black';
  font-weight: 800;
}

.enabledBtn {
  background-color: #01F201;
  border: 1px solid;
  cursor: pointer;
}

.disabledBtn {
  background-color: #454545;
  border: 1px solid;
  cursor: not-allowed;
}

.mainText {
  font-weight: 800;
  font-size: min(2vw, 5vh);
}

.bottomText {
  margin-top: -1vh;
  font-weight: 800;
  font-size: min(1vw, 2vh);
}

.modalFilterStyle {
  background-color: #01F201;
}