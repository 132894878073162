.container {
  background: black;
  background: url('../../assets/images/initial_bg.png');
  background-size: 100vw 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  padding: 6vh 5vw 6vh 5vw;
}

.smallConnectBtn {
  width: 7vw;
  height: 6vh;
}

.mainPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

/* TOP PART */
.logo {
  height: 8vh !important;
}

/* MIDDLE PART */
.middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 2vh;
}

.scienceLabLogo {
  min-width: 20vw;
}

.mainText {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  font-weight: 800;
  font-size: 1.9vw;
  max-width: 25vw;
  font-family: 'Open Sans', sans-serif;
}

.minorText {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: rgb(122, 122, 122);
  font-weight: 600;
  font-size: 0.7vw;
  max-width: 27vw;
  font-family: 'Open Sans', sans-serif;
}