.btn {
  background-color: black;
  border: 1px solid;
  border-color: rgb(46, 46, 46);
  border-radius: 5px;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8vw;
  width: 5vw;
  height: 5.3vh;
}