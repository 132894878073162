.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.img {
  padding: 3px;
  border-radius: 2.5vw;
  padding: 1vw;
  width: 100%;
}

.ominousBg {
  position: absolute;
  background-size: 100% 100%;
  background-image: url('../../assets/images/Union_cropped.svg');
  width: 100%;
  height: 100%;
  padding: 1vw;
}