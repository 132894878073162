.btn {
  border: 1px solid;
  border-radius: 5px;
  height: 5.6vh;
  cursor: pointer;
  font-weight: 800;
  font-size: 0.8vw;
}

.quarantineBtn {
  border-color: #AD1100;
  background-color: #490700;
  color: white;
}

.unquarantineBtn {
  border-color: #484848;
  background-color: black;
  color: white;
}

.modalFilterStyle {
  background-color: #ff0000;
}