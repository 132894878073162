.container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  gap: 0.5vw;
  font-size: 2vh;
  font-weight: 600;
}

.description {
  color: white;
}

.emptyStatus {
  color: #818181;
}

.invalidStatus {
  color: #FF9900;
}

.validStatus {
  color: #01F201;
}