.btn {
  display: flex;
  width: 63vw;
  height: 8.4vh;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid;
  border-radius: 1vw;
  border-color: #01F201;
  background-color: #01F201;
  font-family: 'SuisseIntl-Black';
  font-weight: 800;
}

.mainText {
  font-size: 2vw;
}

.bottomText {
  font-size: 0.8vw;
}