.grayboy {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color:white;
  cursor: pointer;
  position: relative;
  margin-top: 1vw;
}

.grayboyImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20vw;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

/* Loading layer */
.loadLayer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  background-color: rgb(41, 41, 41);
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 2vw;
}

/* Actual grayboy image */
.blackLayer {
  position: absolute;
  aspect-ratio: 1 / 1;
  border-radius: 2vw;
  background-color: black;
  background-clip: content-box, padding-box;
  padding: 5px;
  width: 100%;
  opacity: 0.8;
  z-index: 6;
}

.grayboyImg {
  position: absolute;
  border: 1px solid;
  border-color: transparent;
  background-color: black;
  background-clip: content-box, padding-box;
  padding: 5px;
  border-radius: 2vw;
  aspect-ratio: 1 / 1;
  width: 100%;
  z-index: 5;
}

.grayboyNumber {
  font-weight: 400;
  font-size: 2vh;
  font-family: 'Open Sans', sans-serif;
  margin-top: 5px;
}

.mutatedText {
  position: absolute;
  width: 50%;
  z-index: 7;
}

/* Ominous bg */
.ominousBg {
  border: 5px solid;
  border-color: #01F201;
  border-radius: 2vw;
  padding: 15px;
  filter: blur(20px);
  position: absolute;
  top: 0px;
  left: 0px;
  aspect-ratio: 1 / 1;
  width: 100%;
}

.greenBorder {
  border-color: #01F201 !important;
}

.grayboy:hover .grayboyImg {
  border-color: #ffffff;
}

/* Mutation data */
.mutationLayer {
  position: absolute;
  aspect-ratio: 1 / 1;
  padding: 1.5vw;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.mutationIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #01F201;
  z-index: 6;
  border-radius: 50%;
  margin-left: 1vw;
  padding: 1vw;
}

.mutationSymbol {
  position: absolute;
  color: black;
  font-size: 0.7vw;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
}