.container {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8vw;
  color: white;
  font-size: 3rem;
  width: 100%;
  height: 100%;
  position: absolute;
  font-family: 'SuisseIntl-Black';
  text-align: center;
  flex-direction: column;
}

.logo {
  margin-bottom: 5vh;
  height: 20rem !important;
}