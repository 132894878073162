.btn {
  background-color: black;
  border: 1px solid;
  border-radius: 5px;
  border-color: rgb(46, 46, 46);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  width: 8vw;
  height: 5vh;
}

.btn img {
  width: 10px;
  margin-right: 10px;
}

.text {
  font-size: 0.7vw;
}