.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 0;
  color: white;
  position: relative;
  cursor: pointer;
}

.formulaImg {
  aspect-ratio: 1 / 1;
  width: 100%;
  border-radius: min(1vw, 2vh);
}

.number {
  position: absolute;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 1vw;
  opacity: 0.3;
}

.itemCount {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5%;
  left: 5%;
  border-radius: 50%;
  background-color: #01F201;
  font-weight: 800;
  font-size: 0.6vw;
  font-family: 'Open Sans', sans-serif;
  padding: 0.7vw;
}

.amount {
  color: black !important;
  position: absolute;
}

.removeImg {
  position: absolute;
  right: 5%;
  top: 5%;
  width: 1.2vw;
  height: auto;
}