.container {
  background-color: black;
  border: 1px solid;
  border-color: #FF121E;
  border-radius: 1.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2vh;
  min-height: 62.82vh;
  width: 37.78vw;
  padding: 4vh 3vw 4vh 3vw;
  z-index: 100;
}

.centerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.imgContainer {
  max-width: 50%;
}

.areYouSure {
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1.8vw;
  max-width: 90%;
}

.warning {
  color: #818181;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8vw;
}

.confirmButton {
  background-color: #FF121E;
  border: 1px solid;
  border-color: #FF121E;
  border-radius: 1vw;
  width: 100%;
  min-height: 8.93vh;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 2vw;
  cursor: pointer;
}

.cancelButton {
  background-color: transparent;
  color: #999999;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 1vw;
  letter-spacing: 2px;
  border-color: transparent;
  cursor: pointer;
}