.container {
  display: flex;
  justify-content: center;
  gap: 0.7vw;
}

.wrapper {
  display: inline-flex;
  flex-direction: column;
  width: min-content;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.items {
  display: flex;
  gap: 0.8vw;
}

.semiDividerLeft {
  border-width: 1px 0px 0px 2px;
  border-image: linear-gradient(180deg, rgb(255, 255, 255), rgba(255, 255, 255, 0)) 1;
  height: 10px;
  opacity: 0.9 !important;
}

.semiDividerRight {
  border-width: 1px 2px 0px 0px;
  border-image: linear-gradient(180deg, rgb(255, 255, 255), rgba(255, 255, 255, 0)) 1;
  height: 10px;
  opacity: 0.9 !important;
}

.text {
  color: white;
  word-wrap: nowrap;
  white-space: nowrap;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  font-weight: 800;
  font-size: 1.4vh;
  font-family: 'Open Sans', sans-serif;
}