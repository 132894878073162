.container {
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(50px);
  overflow: hidden;
  padding: 3vh 3.5vw 3vh 3vw;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  z-index: 5;
}

.inputContainer {
  margin-top: 1vh;
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
}

.inputTag {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1.0vw;
  width: 2vw;
  height: 2vw;
  background-color: rgb(49, 49, 49);
  border-radius: 5px 0px 0px 5px;
}

.input {
  border: 0px;
  background-color: rgb(109, 109, 109);
  outline: none;
  color: white;
  display: flex;
  text-align: center; 
  width: 4vw;
  height: 2vw;
  padding: max(10px, 0.2vw);
  border-radius: 0px 5px 5px 0px;
}

.input::placeholder {
  color: white;
}

.removeImg {
  width: 3vw;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}