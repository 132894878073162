.test {
  background: url('../../assets/images/main_bg.png');
  background-size: 100vw 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  max-width: 197vh;
  height: 100vh;
  padding: 3vh 4vw 3vh 4vw;
}

.formulaStatus {
  margin-left: 3vw;
  margin-right: 3vw;
}

.smallConnectBtn {
  width: 7vw;
  height: 6vh;
}

.bottom {
  display: inline-flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  gap: 1vh;
}

.bottomDescriptors {
  display: flex;
  color: white;
  font-size: 1.7vh;
  justify-content: space-between;
}

.bottomActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: min(2vw, 4vh);
}

.outlet {
  position: absolute !important;
  top: 0;
  left: 0;
  margin: 0px;
  overflow: hidden;
}

.topActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}