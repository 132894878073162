.redSerum {
  border: 1px solid;
  border-color: red;
  box-shadow: 0px 0px 25px 1px #ff000079;
}

.blueSerum {
  border: 1px solid;
  border-color: #5BBED2;
  box-shadow: 0px 0px 25px 1px #00d5ff79;
}

.ancientCrystal {
  border: 1px solid;
  border-color: #fbff00;
  box-shadow: 0px 0px 25px 1px #abd10079;
}

.greenSerum {
  border: 1px solid;
  border-color: #01F201;
  box-shadow: 0px 0px 25px 1px #00ff0079;
}

.earlyAccessKeycard {
  border: 1px solid;
  border-color: #0003b9;
  box-shadow: 0px 0px 25px 1px #1100ff79;
}

.embryo {
  border: 1px solid;
  border-color: #7c0067;
  box-shadow: 0px 0px 25px 1px #ff00d479;
}

/* Embryo mint */
.embryoMintContainer {
  border-color: #7c0067 !important;
}

.embryoMintButton {
  background-color: #7c0067 !important;
  border-color: #7c0067 !important;
}

.embryoMintCancelButton {
  color: #7c0067 !important;
}

.embryoMintFilterStyle {
  background: rgb(255, 0, 255);
}