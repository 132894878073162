.container {
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(50px);
  width: 100vw;
  height: 100vh;
  z-index: 5;
  padding: 4vh 3vw 4vh 3vw;
}

.removeImg {
  width: 3vw;
  cursor: pointer;
}