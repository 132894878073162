.container {
  background-color: black;
  padding: 10vh 4vw 10vh 4vw;
  border: 1px solid;
  border-radius: 15px;
  border-color: rgb(15, 238, 3);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 2vh;
  z-index: 2;
}

.text {
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 1.5vw;
  max-width: 15vw;
  margin-bottom: 50px;
}

.btn {
  background-color: rgb(15, 238, 3);
  border: 1px solid;
  border-color: rgb(15, 238, 3);
  border-radius: 10px;
  width: 15vw;
  height: 5vh;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1vw;
  cursor: pointer;
}