.container {
  background-color: black;
  border: 1px solid;
  border-color: #01F201;
  border-radius: 1.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2vh;
  width: 37.78vw;
  padding: 0px 2vw 2vh 2vw;
  z-index: 10;
}

.centerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tokenImg {
  width: 19.04vw;
}

.areYouSure {
  margin-top: -4vh;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 2vw;
}

.warning {
  color: #818181;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8vw;
}

.confirmButton {
  background-color: #01F201;
  border: 1px solid;
  border-color: #01F201;
  border-radius: 15px;
  width: 100%;
  min-height: 8.43vh;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  font-size: 2vw;
  cursor: pointer;
}

.cancelButton {
  background-color: transparent;
  color: #01F201;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 1vw;
  letter-spacing: 2px;
  margin-top: 15px;
  border-color: transparent;
  cursor: pointer;
}