.container {
  background-image: linear-gradient(135deg, #000000 41.67%, #123708 41.67%, #123708 50%, #000000 50%, #000000 91.67%, #123708 91.67%, #123708 100%);
  background-size: 0.5vw 0.5vw;
  border: 1px solid;
  border-color: #01F201;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh 3vw 3vh 3vw;
  z-index: 1;
  gap: 1vh;
}

.mutatingText {
  color: #01F201;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'FlyingSausage-Fill';
  font-size: 4vw;
  margin-bottom: -1vh;
}

.mutatingNote {
  color: #01F201;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.5vw;
}

.blend {
  background: #123708;
}