.container {
  width: min(11.64vw, 25vh);
  height: 8.34vh;
  border: 1px solid;
  border-color: #484848;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tokenImg {
  height: 8.2vh;
}

.cost {
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1.2vw;
}