.container {
  border: 1px solid;
  border-radius: 0.5vw;
  border-color: #484848;
  cursor: pointer;
  display: flex;
  width: min(11.64vw, 25vh);
  height: 8.34vh;
  padding: 0.2vw;
  padding-right: 15px;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.imagesContainer {
  margin-left: -0.2vw;
  position: relative;
  height: 100%;
}

.grayboyImg {
  left: calc(0.2vw);
  position: absolute;
  border-radius: 0.3vw;
  height: 100%;
  z-index: 2;
}

.grayboyImg2 {
  left: calc(0.2vw + 10%);
  position: absolute;
  border-radius: 0.3vw;
  filter: brightness(0.5);
  background-color: black;
  height: 100%;
  z-index: 1;
}

.grayboyImg3 {
  left: calc(0.2vw + 20%);
  position: absolute;
  border-radius: 0.3vw;
  filter: brightness(0.05);
  height: 100%;
}

.grayboyId {
  color: white;
  font-weight: 800;
  font-size: max(2vh, 10px);
  font-family: 'Open Sans', sans-serif;
}