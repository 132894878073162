.container {
  background-color: black;
  border: 1px solid;
  border-color: #01F201;
  border-radius: 1.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  width: 37.78vw;
  padding: 4vh 2vw 4vh 2vw;
}

.scientistImg {
  width: 7.48vw;
}

.areYouSure {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 2.0vw;
}

.warning {
  color: #818181;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8vw;
}

.confirmButton {
  background-color: #01F201;
  border: 1px solid;
  border-color: #01F201;
  border-radius: 0.7vw;
  width: 100%;
  min-height: 8.5vh;
  font-family: 'SuisseIntl-Black';
  font-weight: 800;
  font-size: 1.8vw;
  cursor: pointer;
}

.cancelButton {
  background-color: transparent;
  color: #999999;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 1.1vw;
  border-color: transparent;
  cursor: pointer;
}