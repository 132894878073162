.openseaBtn {
  background-color: black;
  color: white;
  border: 1px solid;
  border-color: #484848;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8vw;
  font-weight: 600;
  cursor: pointer;
  width: 12.5vw;
  height: 6vh;
}