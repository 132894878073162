.container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  gap: 0.7vh;
}

.itemImg {
  border: 2px solid;
  border-radius: 10px;
  border-color: #484848;
  width: min(4vw, 10vh);
  height: min(4vw, 10vh);
  cursor: pointer;
  -webkit-box-shadow: inset 0px 0px 25px 100px #000000; 
  box-shadow: inset 0px 0px 25px 100px #000000;
}

.mintBtn {
  background-color: black;
  color: white;
  opacity: 0;
  border: 1px solid;
  border-color: #484848;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.5vw;
  height: 2.2vh;
}

.container:hover .mintBtn {
  opacity: 1;
}

.container:hover .itemImg {
  border-color: #449FA5;
}

.amountBubble {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 50%;
  background-color: #01F201;
  font-weight: 800;
  font-size: 0.6vw;
  font-family: 'Open Sans', sans-serif;
  padding: 0.5vw;
}

.amount {
  position: absolute;
}

.disabledContainer {
  border: 2px solid;
  border-radius: 10px;
  border-color: #484848;
  position: absolute;
  background-color: rgba(65, 65, 65, 0.356);
  aspect-ratio: 1 / 1;
  border-radius: 10px;
  width: min(4vw, 10vh);
  height: min(4vw, 10vh);
  cursor: not-allowed;
}