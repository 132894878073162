.btn {
  background-color: black;
  border: 1px solid;
  border-color: rgb(46, 46, 46);
  border-radius: 5px;
  width: 5.3vh;
  height: 5.3vh;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.btn img {
  width: 90%;
  height: auto;
}