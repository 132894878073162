.container {
  font-family: 'Open Sans', sans-serif;
  margin-top: 2vh;
  width: 27.5vw;
}

.title {
  line-height: 4vh;
  color: white;
  font-weight: 800;
  max-width: min(25vw, 50vh);
  font-size: min(2.2vw, 4vh);
  letter-spacing: 1px;
}

.content {
  margin-top: 2vh;
  color: rgb(170, 170, 170);
  font-weight: 800;
  max-width: min(25vw, 50vh);
  font-size: min(0.9vw, 2vh);
}